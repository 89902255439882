import React, { useEffect, useState } from 'react'
import * as myStyles from './styles.module.scss'
import {
    requestDemoFormErrorButtonLabel,
    requestDemoFormErrorButtonLink,
    requestDemoFormErrorDescription,
    requestDemoFormErrorTitle,
    getRequestDemoFormFields,
    requestDemoFormPardotHandler,
    requestDemoFormSubmitButtonLabel,
    requestDemoFormSuccessButtonLabel,
    requestDemoFormSuccessButtonLink,
    requestDemoFormSuccessDescription,
    requestDemoFormSuccessTitle,
    requestDemoFormTextBlock,
    requestK2KDemoFormSubmitButtonLabel,
    requestK2KDemoFormSuccessButtonLabel,
    requestK2KDemoFormSuccessButtonLink,
    requestK2KDemoFormTextBlock,
} from './constants'
import { useForm } from 'react-hook-form'
import DynamicForm from '../../lib/components/components/dynamic-form'
import FlexibleModule from '../../lib/components/modules/flexible'
import { FormState } from '../../lib/components/components/dynamic-form/types'

const RequestDemoPage = () => {
    const styles: any = myStyles

    const [requestDemoFormStatus, setRequestDemoFormStatus] = useState<FormState>('empty')
    const [product, setProduct] = useState('none')

    const requestDemoForm = useForm<{
        ['First Name']: string
        ['Last Name']: string
        Email: string
        Company: string
        ['Trial Source']: string
    }>()

    useEffect(() => {
        const url = new URL(window.location.href)

        const status = url.searchParams.get('status')
        if (status === 'success') setRequestDemoFormStatus('success')
        if (status === 'error') setRequestDemoFormStatus('error')
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href)
        const product = url.searchParams.get('product')
        if (product !== null || product !== '') setProduct(product)
    }, [])

    const textBlock = product === 'k2k' ? requestK2KDemoFormTextBlock : requestDemoFormTextBlock

    const formSubmitButtonLabel =
        product === 'k2k' ? requestK2KDemoFormSubmitButtonLabel : requestDemoFormSubmitButtonLabel

    const formSuccessButtonLabel =
        product === 'k2k' ? requestK2KDemoFormSuccessButtonLabel : requestDemoFormSuccessButtonLabel
    const formSuccessButtonLink =
        product === 'k2k' ? requestK2KDemoFormSuccessButtonLink : requestDemoFormSuccessButtonLink

    return (
        <div className={styles.m_request_demo}>
            <div className={styles.content}>
                <FlexibleModule textBlocks={textBlock} hasBorder={false}>
                    <DynamicForm
                        status={requestDemoFormStatus}
                        fields={getRequestDemoFormFields(product)}
                        form={requestDemoForm}
                        action={requestDemoFormPardotHandler}
                        submitButtonLabel={formSubmitButtonLabel}
                        dataLayer={{
                            event: 'request_demo_success',
                        }}
                        responseMessage={{
                            title:
                                requestDemoFormStatus === 'success'
                                    ? requestDemoFormSuccessTitle
                                    : requestDemoFormErrorTitle,
                            description:
                                requestDemoFormStatus === 'success'
                                    ? requestDemoFormSuccessDescription
                                    : requestDemoFormErrorDescription,
                            buttonLabel:
                                requestDemoFormStatus === 'success'
                                    ? formSuccessButtonLabel
                                    : requestDemoFormErrorButtonLabel,
                            buttonLink:
                                requestDemoFormStatus === 'success'
                                    ? formSuccessButtonLink
                                    : requestDemoFormErrorButtonLink,
                        }}>
                        <input type='hidden' name='Product Name' value={product} />
                    </DynamicForm>
                </FlexibleModule>
            </div>
        </div>
    )
}

export default RequestDemoPage
